import { ClientGlobals } from './types'
import makeObjectPropertiesReadOnly from './makeObjectPropertiesReadOnly'

export const freezeClientGlobals = (isExcludedFromSecurityExperiments: boolean) => {
	let globalMethodsToFreeze: Array<ClientGlobals> = []
	let globalObjectsToFreeze: Array<ClientGlobals> = []

	// @ts-expect-error
	const { experiments } = window.viewerModel

	// Check which freeze to apply
	if (experiments['specs.thunderbolt.softFreeze_TextDecoder_TextEncoder']) {
		globalObjectsToFreeze = globalObjectsToFreeze.concat(['TextEncoder', 'TextDecoder'])
	}

	if (
		experiments['specs.thunderbolt.hardenClientGlobals_EventTarget'] &&
		experiments['specs.thunderbolt.moveSentryToHeadBeforeSecurityChanges'] &&
		!isExcludedFromSecurityExperiments
	) {
		globalObjectsToFreeze = globalObjectsToFreeze.concat(['XMLHttpRequestEventTarget', 'EventTarget'])
	}

	// TODO currently turned off because both Webpack and other libraries are overriding Array.prototype methods
	if (experiments['specs.thunderbolt.hardenArray']) {
		globalObjectsToFreeze.push('Array')
	}

	if (experiments['specs.thunderbolt.harden_URL_JSON']) {
		globalObjectsToFreeze = globalObjectsToFreeze.concat(['URL', 'JSON'])
	}

	if (!isExcludedFromSecurityExperiments) {
		globalMethodsToFreeze = globalMethodsToFreeze.concat(['addEventListener', 'removeEventListener'])
	}

	if (experiments['specs.thunderbolt.hardenEncodingDecoding']) {
		globalMethodsToFreeze = globalMethodsToFreeze.concat([
			'encodeURI',
			'encodeURIComponent',
			'decodeURI',
			'decodeURIComponent',
		])
	}

	if (experiments['specs.thunderbolt.hardenStringAndNumber']) {
		globalObjectsToFreeze = globalObjectsToFreeze.concat(['String', 'Number'])
	}

	if (experiments['specs.thunderbolt.hardenObject'] && !isExcludedFromSecurityExperiments) {
		globalObjectsToFreeze.push('Object')
	}

	if (experiments['specs.thunderbolt.hardenReflect']) {
		globalObjectsToFreeze = globalObjectsToFreeze.concat(['Reflect'])
	}

	globalMethodsToFreeze.forEach((key) => {
		// Freeze the object
		Object.freeze(globalThis[key])

		if (['addEventListener', 'removeEventListener'].includes(key)) {
			// @ts-expect-error
			globalThis.defineStrictProperty(key, document[key], document, true)
		}

		// @ts-expect-error
		globalThis.defineStrictProperty(key, globalThis[key], globalThis, true)
	})

	globalObjectsToFreeze.forEach((key) => {
		/**
		 * We are freezing objects in a specific way for the following reasons:
		 * 1. Object.freeze does not allow extending the object with new properties.
		 * 2. Freezing Object.prototype can prevent changes to the prototype chain, including property additions or
		 * 		modifications. When Object.prototype is frozen, attempts to set properties on objects that inherit from it
		 * 		can lead to unexpected behavior.
		 * 		For more details, see: https://esdiscuss.org/topic/set-and-inherited-readonly-data-properties
		 */
		makeObjectPropertiesReadOnly(key, globalThis)
	})
}
